import { useState, useEffect } from 'react'
import { MDBSpinner } from 'mdb-react-ui-kit';
import Breadcrumb from '../../Components/Core/BreadCrumb';
import Banners from '../../Components/Core/Banners';
import { useStoryblok,StoryblokComponent } from '@storyblok/react';
import { Link } from 'react-router-dom';
import RichText from '../../Components/Core/RichText.js';

const items = [
    {to:'/ProducerPortal',label:'Home'},
    {to:'/Newsletters',label:'Lonestar MGA Newsletters'}
]
const Newsletters = () => {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;
    const story = useStoryblok("lonestar/producer/newsletter", {version:storyblokVersion});
    if (!story?.content) {
        return <div className="loader-section">
                    <MDBSpinner color='danger' className="spinner"></MDBSpinner>
                </div>
    }

    return ( 
        <>
            <Banners img={'https://cdn.lonestarmga.com/img/Banners/newslettter-banner_1920x357.svg'} mobileImg={'https://cdn.lonestarmga.com/img/Banners/newslettter-banner_1920x357.svg'} page={"Lonestar Newsletters"} category={"Producers"} />      
            <div className="white-smoke-background"> 
                <div className="flex-center">
                    <div className={windowSize.innerWidth < 850 ? 'standard-page-width flex-start px-4' : 'standard-page-width flex-start px-3 py-4'}>
                            <Breadcrumb>{
                                items.map(({to, label}) =>(           
                                    <Link key={to} to={to}>{label}</Link>
                                ))}
                            </Breadcrumb>
                        </div>
                    </div>
                <div>
                    <StoryblokComponent blok={story.content} />
                </div>
                <div>
                    <div className='grid justify-content-center px-3'>
                        <div className='px-3 newsletter-page-section' >
                            <div style={{ fontSize: "40px", fontFamily: "Poppins-Regular" }}>WIN Partner Resources</div>
                            <div className='pl-1 py-4'>
                                <div>
                                    <div className="regular-newsletter-body gap-1 resource-body flex">
                                        <div className="flex-basis-6-full">
                                            <div className="flex gap-2 newsletter-topic pb-2"><span>Resource</span><span>September 2024</span></div>
                                            <div className="mobile-newsletter-title paragraph-text"><b>Introducing WIN Partner Resources</b></div>
                                            <div className="py-2">
                                                <a href="https://cdn.lonestarmga.com/pdf/WIN Introducing WIN Partner Resources 8.5x11 (1).pdf" rel="noreferrer" target="_blank" className="primary-link-text">Learn More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                
        </>
     );
} 
 
export default Newsletters;