import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { StoryblokComponent, useStoryblok } from "@storyblok/react";
import CardGrid from '../../Components/Cards/CardGrid';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import DependentMediaObject from '../../Components/DependentMediaObject';
import styled from "styled-components";
import ContentObject from '../../Components/ContentObject';
import Announcement from '../../Components/Announcement';
import StoryblokClient from "storyblok-js-client"
import ContentHeaderOnly from '../../Components/ContentHeaderOnly';
import { MDBSpinner } from 'mdb-react-ui-kit';
import FeaturedNews from '../../Components/FeaturedNews';
import FeaturedTopNews from '../../Components/FeaturedTopNews';
import { useAuth } from '../../utils/AuthProvider';
import { DBMessage } from '../../utils/DBMessage';
import Carousel from '../../Components/Carousel';

const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;
const storyblokToken = process.env.REACT_APP_STORYBLOK_TOKEN;

const Storyblok = new StoryblokClient({
    accessToken: storyblokToken,
    cache: {
        clear: 'auto',
        type: 'memory'
    }
})
export default function ProducerPortal() {

    const [AlertAnnouncement, setAlertAnnouncement] = useState([]);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [ActiveMessage, setActiveMessage] = useState(false);
    const auth = useAuth();
    const currentDate = new Date();
    const targetStartDate = new Date("2024-11-30T18:00:00");
    //const end = new Date("2024-04-30T19:00:00"); VA: test, replace to test on ln79
    
    useEffect(() => {
        const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;

        Storyblok.get('cdn/stories', {
            "token": storyblokToken,
            "starts_with": "lonestar/alerts-and-announcements/",
            "version": storyblokVersion
        })
            .then(response => {
                setAlertAnnouncement(response.data.stories);

            }).catch(error => {
                console.log(error)
            })
    }, []);

    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
    }, []);


    useEffect(() => {
        let agentId = JSON.parse(localStorage.getItem("DATA"))?.rtnval?.id;

        if (agentId <= -1) {
            auth.logout();
        }

        if (currentDate >= targetStartDate) {
            setActiveMessage(false)
        } else {
            setActiveMessage(true)
        }
    });
      function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
      }
    const systemAccessStory = useStoryblok("lonestar/producer/systems-access-protected", { version: storyblokVersion });
    const PTSstory = useStoryblok("lonestar/producer/what-is-pts", { version: storyblokVersion });
    const servicesStory = useStoryblok("lonestar/producer/producer-services", { version: storyblokVersion });
    const actionsStory = useStoryblok("lonestar/producer/customer-policy-actions", { version: storyblokVersion });
    const ResourceStory = useStoryblok("lonestar/customer-content/online-resources", { version: storyblokVersion });
    const BtnResource1Story = useStoryblok("lonestar/producer/online-resources-button-set-1", { version: storyblokVersion });
    const BtnResource2Story = useStoryblok("lonestar/producer/online-resources-button-set-2", { version: storyblokVersion });
    const BlogStory = useStoryblok("lonestar/producer/lonestar-info-center", { version: storyblokVersion });
    const NewsStory = useStoryblok("lonestar/producer/newsletter", { version: storyblokVersion });
    const WhatsHappening = useStoryblok("lonestar/producer/see-what-s-happening", { version: storyblokVersion });
    const portalHomeStory = useStoryblok("lonestar/producer/producer-portal-home", { version: storyblokVersion });
    const textOnlyTitle = useStoryblok("lonestar/pages/producer-portal/ca-quoting-service-text", { version: storyblokVersion });

    if (!portalHomeStory?.content || !servicesStory?.content || !actionsStory?.content || !ResourceStory?.content 
        || !BtnResource1Story?.content || !BtnResource2Story?.content || !BlogStory?.content || !textOnlyTitle?.content
        || !systemAccessStory?.content || !PTSstory?.content || !NewsStory?.content || !WhatsHappening.content) {
        return <div className="loader-section">
            <MDBSpinner color='danger' className="spinner"></MDBSpinner>
        </div>
    }

    const allNewsArray = NewsStory.content.body.find((item) => {
        return item.all_news !== undefined;
    }).all_news;

    const topNewsArray = NewsStory.content.body.find((item) => {
        return item.top_news !== undefined;
    }).top_news;
    const BannerSection = styled.div`
    background:url(${'https://cdn.lonestarmga.com/img/Banners/producerportal.png'}) no-repeat center bottom;
    width:100%;
    height:350px;
    background-size: cover;
    @media only screen and (max-width: 800px){
          background:url(${"https://cdn.lonestarmga.com/img/Banners/mobile/producerportal_mobile.png"}) center center fill;
          background-repeat:no-repeat;
          background-size: fill;
    }`;

    return (
        <>
            <div className="flex-center">
                <BannerSection className="header-section header-section-producer-portal" >
                    <div className="py-2">
                        <div className="col-lg-6 col-md-6 col-sm-12 m-auto text-center py-4">
                            <div className='banner-sub-heading'>
                                Producer Portal
                            </div>
                            <div className='banner-heading'>
                                Welcome to Lonestar MGA
                            </div>
                        </div>
                        <div>
                            {
                                !ActiveMessage ? 
                                    auth.MaintenanceMessage.isDown ? (
                                        <DBMessage messageHTML={auth.MaintenanceMessage.messageHTML} style={"alert-danger"} />
                                    ):(
                                            <div className='row justify-content-center pl-2 pr-2' style={{paddingTop: "7%"}}>
                                                <Link to={"/Announcements"} className='col-lg-12'>
                                                    <Announcement data={AlertAnnouncement} landing="true" />
                                                </Link>
                                            </div>
                                    )
                                : (
                                        <div className={`row no-gutters justify-content-center px-4`}>
                                            <div className="col-lg-9 alert alert-danger p-3">
                                                <span className="text-notification">
                                                    Attention! Commercial Auto Producers. The Quote New/ Existing Quotes and Policy Inquiry/ Post Payments are offline between 6:00 PM CST on 11/30/2024 through 10:00 PM CST on 11/30/2024 for maintenance. We appreciate your patience and understanding, as we look to further improve these programs for your use.
                                                </span>
                                            </div>
                                        </div>
                                )
                            }
                            <div className='row justify-content-center pl-2 pr-2' style={{ paddingTop: "7%" }}>
                                    <Link to={"/Announcements"} className='col-lg-12'>
                                        <Announcement data={AlertAnnouncement} landing="true" />
                                    </Link>
                            </div>
                        </div>
                    </div>
                </BannerSection>
            </div>
            <Carousel/>
            <div className='flex-center pt-4'>
                <div className='banner-sub-heading' style={{ maxWidth: "1300px", paddingLeft: "2.4%" }}>
                    Systems Access
                </div>
            </div>
            <div className='flex-center wrap pb-4'>
                <div className='white-body-accordion'>
                    <DependentMediaObject data={systemAccessStory.content} />
                    <div className="divider" />
                    <ContentObject data={PTSstory.content} />
                </div>
            </div>
            <h3 className="flex-center pb-4">
                Producer Actions
            </h3>
            <div className='flex-center'>
                <div className='flex-center wrap px-4' style={{ gap: "50px", maxWidth: "1300px" }}>
                    <CardActions className="mobile-space-sm justify-content-center" >
                        <CardGrid data={actionsStory.content} />
                    </CardActions>
                    <CardActions className='mobile-space-sm justify-content-center'>
                        <CardGrid data={servicesStory.content} />
                    </CardActions>
                </div>
            </div><br /><br />
            <div className="heading-large flex-center">
                {textOnlyTitle.content.heading}
            </div>
            {
                textOnlyTitle.content.subheading !== "" && (
                    <div className="flex-center sub-heading-red pt-2">
                        {textOnlyTitle.content.subheading}
                    </div>
                )
            }
            <div>
                <StoryblokComponent blok={portalHomeStory.content} />
                <div className='flex-start' style={{maxWidth:"970px", margin:"0 auto"}}>
                    <div>
                        <img className="col-sec-bottom-image" src={'https://cdn.lonestarmga.com/img/3trucks_757x90.svg'} alt="Trucks driving past mountain" />
                    </div>
                </div>
            </div>
            <div style={{ minHeight: "400px", backgroundColor: "#DCDCDC", padding: "2.5% .5%" }}>
                <div>
                    <ContentHeaderOnly data={ResourceStory.content} headingPosition="center" />
                </div>
                <div className="flex-center gap-5 mobile-gap-none">
                    <div className="flex-center wrap-button-cols px-4" style={{ width: "1000px", alignItems: "center" }}>
                        <div className="flex-basis-5-full">
                            <ButtonGroup data={[BtnResource1Story.content]} />
                        </div>
                        <span className='break-btn-group'></span>
                        <div className="flex-basis-5-full mobile-padding-bottom-10 ">
                            <ButtonGroup data={[BtnResource2Story.content]} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='grid space-reg justify-content-center ' style={{ paddingTop: "2%" }}>
                <div className='flex-center'><ContentHeaderOnly data={WhatsHappening.content} /></div>
                <div className="newsletters-cards">
                    <FeaturedTopNews data={topNewsArray} />
                    <FeaturedNews data={allNewsArray} />
                </div><br />
                <div className='newsletter-btn-section py-4'>
                    <Link className='primary-sm-pill-btn newsletter-button' to='/Newsletters' >View All News</Link>
                </div>
            </div>
            <br />
        </>
    )
}

const CardActions = styled.div`

  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 450px;
  @media only screen and (max-width: 1250px) {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  @media only screen and (max-width: 800px) {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    display:contents;
  }
  
`;


